<template>
  <div>
    <div class="piccheck">
      <div>图片检查</div>
      <div v-for="(item,index) in sightNewImgs" :key="index" style="margin:0 10px;">
        <el-button type="primary" @click="tocheckImg(item)" size="mini">{{ item }}</el-button>
      </div>
    </div>
    <div class="piccheck">景点审核</div>
    <div v-for="(item,index) in sights" :key="index+'a'" class="sightbox">
      <el-row :gutter="100">
        <el-col :span="12">
          <table>
            <tr>
              <td width="120px">操作</td>
              <td>
                <el-button type="primary" size="mini" @click="mapCheck(item)">地图查看</el-button>
                <el-button type="warning" size="mini" @click="openSightEdit(item)">编辑</el-button>
              </td>
            </tr>
            <tr>
              <td width="120px">名称</td>
              <td>{{item.name}} . {{ item.id }}</td>
            </tr>
            <tr>
              <td>地址</td>
              <td>{{item.province}}{{item.city}}{{item.district}}{{item.address}}-{{item.adcode}}</td>
            </tr>
            <tr>
              <td>类别星级</td>
              <td>{{item.typeName}} ~ {{item.stars}}</td>
            </tr>
            <tr>
              <td>时间</td>
              <td>{{item.start}} ~ {{item.end}}</td>
            </tr>
            <tr>
              <td>电话</td>
              <td>{{item.phone}}</td>
            </tr>
            <tr>
              <td>介绍</td>
              <td>{{item.intro}}</td>
            </tr>
            <tr>
              <td>门票</td>
              <td>{{item.tickets[0].price}}</td>
            </tr>
            <tr>
              <td>门票介绍</td>
              <td>{{item.tickets[0].intro}}</td>
            </tr>
            <tr>
              <td>提交人</td>
              <td
                class="userwrap"
                @click="toUserDetail(item.user.idUser)"
              >{{item.user.nickName}} . {{item.user.regphoneUser}}</td>
            </tr>
            <tr>
              <td>提交时间</td>
              <td>{{item.genTime}}</td>
            </tr>
          </table>
        </el-col>
        <el-col :span="12">
          <div class="arroundbox" v-if="item.sightsArround.length>0">
            <div style="color:red;font-weight:bold">周边景点</div>
            <div
              v-for="(near, index) in item.sightsArround"
              :key="index+'a'"
              class="arrounditem"
              @click="toSightDetail(near.id)"
            >{{ near.name }} （{{near.adcode}}）{{near.distance}}</div>
          </div>
          <div>
            <img
              :src="baseUrl+img"
              width="80px"
              height="80px"
              alt
              @click="previewImg(baseUrl+img)"
              style="margin-right:10px;margin-bottom:10px"
              v-for="(img,index) in item.imgs"
              :key="index+'a'"
            />
          </div>

          <div
            v-for="(list,index) in item.positions"
            :key="index+'c'"
            style="margin-top: 20px;padding-top:20px;border-top:1px dashed #aaa"
          >
            <div>
              <span style="font-weight:bold;margin-right:40px">位置点({{list.id}})</span>
              <span>{{list.name}}</span>
            </div>
            <div style="margin:30px 0">
              <span style="font-weight:bold;margin-right:40px">类别</span>
              <span>{{list.typeName}}</span>
            </div>
            <div style="margin:30px 0" v-if="list.intro">
              <span style="font-weight:bold;margin-right:40px">介绍</span>
              <span>{{list.intro}}</span>
            </div>
            <img
              :src="baseUrl+img"
              width="80px"
              height="80px"
              alt
              @click="previewImg(baseUrl+img)"
              style="margin-right:10px;margin-bottom:10px"
              v-for="(img,index) in list.imgs"
              :key="index+'a'"
            />
          </div>
        </el-col>
      </el-row>
      <div class="btnwrap-g">
        <el-button type="danger" size="small" @click="deleteConfirm(index) ">删除无效景区</el-button>
        <el-button
          type="danger"
          size="small"
          @click="rejectSightFn(index)"
          style="margin-left:40px"
        >拒绝，退为草稿</el-button>
        <el-button
          type="success"
          size="small"
          @click="authSightFn(index)"
          style="margin-left:200px"
        >审核同意</el-button>
      </div>
    </div>
    <el-dialog :visible.sync="showImg" top="2vh" destroy-on-close>
      <img :src="img" width="100%" alt />
    </el-dialog>
    <!-- <el-dialog :visible.sync="showMap" top="2vh" destroy-on-close width="70%">
      <mapview :positions="gps" v-if="showMap" />
    </el-dialog> -->
    <el-drawer :visible.sync="editSightShow" direction="rtl" :before-close="handleClose" size="50%">
      <div style="padding: 30px;">
        <el-form
          ref="sightSelected"
          :model="sightSelected"
          :rules="rules"
          label-width="100px"
          hide-required-asterisk
          size="small"
        >
          <el-form-item label="景点名称" prop="name">
            <el-input v-model="sightSelected.name" maxlength="20" placeholder="必填，20字以内"></el-input>
          </el-form-item>
          <el-form-item label="景点介绍" prop="intro">
            <el-input
              v-model="sightSelected.intro"
              :autosize="{ minRows: 5 }"
              type="textarea"
              placeholder="必填，10字以上"
            ></el-input>
          </el-form-item>
          <el-form-item label="景点类别">
            <el-select v-model="sightSelected.type" placeholder="请选择">
              <el-option
                :label="element.name"
                :value="element.value"
                v-for="(element,index) in sightTypes"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="星级">
            <el-select v-model="sightSelected.stars">
              <el-option label="未评级" value="未评级"></el-option>
              <el-option label="1A" value="1A"></el-option>
              <el-option label="2A" value="2A"></el-option>
              <el-option label="3A" value="3A"></el-option>
              <el-option label="4A" value="4A"></el-option>
              <el-option label="5A" value="5A"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开放时间">
            <el-row>
              <el-col :span="11">
                <el-time-select
                  placeholder="起始时间"
                  v-model="sightSelected.start"
                  :picker-options="{ start: '00:00',step: '00:30',end: '24:00'}"
                ></el-time-select>
              </el-col>
              <el-col :span="2" style="text-align:center">-</el-col>
              <el-col :span="11">
                <el-time-select
                  placeholder="结束时间"
                  v-model="sightSelected.end"
                  :picker-options="{ start: '00:00',step: '00:30',end: '24:00',minTime: sightSelected.start}"
                ></el-time-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="服务电话">
            <el-input v-model="sightSelected.phone" placeholder="选填"></el-input>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="sightSelected.address" placeholder="必填"></el-input>
          </el-form-item>
          <el-form-item label="十万八备注">
            <el-input v-model="sightSelected.remark" type="textarea" :autosize="{ minRows: 5 }"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="warning" size="small" @click="editSightShow=false">取消</el-button>
            <el-button type="primary" size="small" @click="updateSightFn('sightSelected')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>删除当前景区？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="adminDeleteSightFn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSightsForAuth,
  authSight,
  rejectSight,
  adminGetSightTypes,
  updateSight,
  adminDeleteSight
} from "@/api/api";
// import mapview from "../components/MapView";
import { mapGetters } from "vuex";
export default {
  // components: {
  //   mapview
  // },
  data() {
    return {
      sights: [],
      showImg: false,
      img: "",
      showMap: false,
      dialogVisible: false,
      sightNewImgs: [],
      editSightShow: false,
      percentage: 0,
      sightTypes: [],
      editType: "",
      sightSelected: "",
      positionTypes: [],
      baseUrl: "https://server.solar960.com",
      rules: {
        name: [{ required: true, message: "请输入景区名称", trigger: "blur" }],
        intro: [
          { required: true, message: "请输入景区介绍", trigger: "blur" },
          { min: 10, message: "不能低于10字", trigger: "blur" }
        ],
        latitude: [{ required: true, message: "请选择定位", trigger: "blur" }],
        type: [{ required: true, message: "请选择类别", trigger: "change" }],
        imgs: [{ required: true, message: "请上传图片", trigger: "blur" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" }
        ]
      },
      headerObj: {
        Accept: "application/x.server.v1+json",
        Authorization: sessionStorage.getItem("Authorization")
      }
    };
  },
  mounted() {
    this.getSightsForAuthFn();
    this.getSightTypes();
  },
  computed: {
    ...mapGetters(["getFirstAuth"])
  },
  methods: {
    getSightsForAuthFn() {
      const that = this;
      getSightsForAuth().then(res => {
        if (res.result) {
          that.sights = res.data.sights;
          that.sightNewImgs = res.data.sightNewImgs;
          that.getFirstAuth.sight =
            res.data.sights.length + res.data.sightNewImgs.length;
          that.$store.dispatch("setFirstAuth", that.getFirstAuth);
        }
      });
    },

    getSightTypes() {
      adminGetSightTypes().then(res => {
        if (res.result) {
          this.sightTypes = res.data.sightTypes;
          this.positionTypes = res.data.positionTypes;
        }
      });
    },

    openSightEdit(item) {
      this.sightSelected = JSON.parse(JSON.stringify(item));
      this.editSightShow = true;
    },
    handleClose() {
      this.sightSelected = {};
      this.editSightShow = false;
    },

    updateSightFn(sightSelected) {
      const that = this;
      let res = new Promise((resolve, reject) => {
        this.$refs[sightSelected].validate(valid => {
          if (!valid) {
            reject(false);
          } else {
            resolve(true);
          }
        });
      });

      if (!res) {
        return;
      }

      updateSight(that.sightSelected).then(res => {
        if (res.result) {
          let index = this.sights.findIndex(item => {
            return item.id == this.sightSelected.id;
          });
          this.sights[index] = this.sightSelected;
          this.editSightShow = false;
        }
      });
    },

    mapCheck(item) {
      this.gps = JSON.parse(JSON.stringify(item.positions));
      this.gps.push(item);
      let sightsArround = item.sightsArround;
      sightsArround.forEach(item => {
        item.iconPath = "/ast/icon1.png";
        this.gps.push(item);
      });
      this.showMap = true;
    },

    previewImg(item) {
      this.showImg = true;
      this.img = item.replace(".thumb", "");
    },

    tocheckImg(id) {
      this.$router.push({
        path: "/sights/sightdetail",
        query: { id: id, img: true }
      });
    },
    authSightFn(index) {
      const that = this;
      this.$confirm("确定审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      })
        .then(() => {
          authSight({
            id: that.sights[index].id
          }).then(res => {
            if (res.result) {
              this.$message({
                type: "success",
                message: that.sights[index].name + "审核已通过"
              });
              that.sights.splice(index, 1);
              that.getFirstAuth.sight =
                that.sights.length + that.sightNewImgs.length;
              that.$store.dispatch("setFirstAuth", that.getFirstAuth);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "取消"
          });
        });
    },
    rejectSightFn(index) {
      const that = this;
      this.$prompt("请输入拒绝的原因", "拒绝", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入拒绝的原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          rejectSight({
            id: that.sights[index].id,
            remark: value
          }).then(res => {
            if (res.result) {
              this.$message({
                type: "error",
                message: that.sights[index].name + "已拒绝"
              });
              that.sights.splice(index, 1);
              that.getFirstAuth.sight =
                that.sights.length + that.sightNewImgs.length;
              that.$store.dispatch("setFirstAuth", that.getFirstAuth);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },

    deleteConfirm(index) {
      this.deleteIndex = index;
      this.dialogVisible = true;
    },

    adminDeleteSightFn() {
      const that = this;
      let index = this.deleteIndex;
      this.dialogVisible = false;

      this.$prompt("请输入删除的原因", "删除 . "+this.sights[index].name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入拒绝的原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          adminDeleteSight({
            id: that.sights[index].id,
            remark: value
          }).then(res => {
            if (res.result) {
              if (res.message == 800) {
                this.$message({
                  type: "success",
                  message: that.sights[index].name + "已删除"
                });
                that.sights.splice(index, 1);
                that.getFirstAuth.sight =
                  that.sights.length + that.sightNewImgs.length;
                that.$store.dispatch("setFirstAuth", that.getFirstAuth);
              } else {
                this.$message({
                  type: "error",
                  message: res.message
                });
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },
    toSightDetail(id) {
      this.$router.push({
        path: "/sights/sightdetail",
        query: { id: id }
      });
    },
    toUserDetail(id) {
      this.$router.push({
        path: "/users/userdetail",
        query: { idUser: id }
      });
    }
  }
};
</script>

<style scoped>
.sightbox {
  padding: 30px;
  margin: 30px;
  border: 1px solid #555;
  border-radius: 10px;
}
.piccheck {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.arroundbox {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #aaa;
}
.arrounditem {
  margin: 10px 0;
  color: #417cad;
  cursor: pointer;
}

.userwrap {
  color: #417cad;
  cursor: pointer;
}
</style>
